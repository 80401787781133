const discountsimg = [
    {
        src: require("../img/DISCOUNTS/bioderma2.png"),
        url: require("../img/DISCOUNTS/bioderma2.png"),
        alt: 'Bioderma'
    },

    {
        url: require("../img/DISCOUNTS/avene.jpg"),
        src: require("../img/DISCOUNTS/avene.jpg"),
        alt: 'Avene'
    },
    {
        url: require("../img/DISCOUNTS/bioderma3.png"),
        src: require("../img/DISCOUNTS/bioderma3.png"),
        alt: 'Bioderma'
    },
    {
        url: require("../img/DISCOUNTS/bioderma.png"),
        src: require("../img/DISCOUNTS/bioderma.png"),
        alt: 'Bioderma'
    },
    {
        url: require("../img/DISCOUNTS/ducray.jpg"),
        src: require("../img/DISCOUNTS/ducray.jpg"),
        alt: 'Ducray'
    }
]

export default discountsimg;